import MainVue from '../../main-vue';
import Checkbox from '../../components/components.checkbox.vue';
import {
    getItemSessionStorage,
    setItemSessionStorage
} from '../../helper-functions/services';

const  numberString = ["--one","--two","--three","--four","--five","--six"];

new MainVue({
    el: '#app',
    components: {
        'v-checkbox': Checkbox
    },
    data: function() {
        return {
            intlStudent: ibJS.intlStudent,
            j1Visa: ibJS.j1Visa,
            covid19TravelMedical: ibJS.covid19TravelMedical,
            visitorOutsideUSA: ibJS.visitorOutsideUSA,
            covid19: ibJS.covid19,
            schengenVisa: ibJS.schengenVisa,
            tripInsurance: ibJS.tripInsurance,
            newImmigrant: ibJS.newImmigrant,
            visitorGC: ibJS.visitorGC,
            imgTravel: ibJS.imgTravel,
            individualMedical: ibJS.individualMedical,
            studyAbroad: ibJS.studyAbroad,
            optHealthIns: ibJS.optHealthIns,
            evacRepatriation: ibJS.evacRepatriation,
            flightAccident: ibJS.flightAccident,
            annualMultitrip: ibJS.annualMultitrip,
            adandd: ibJS.adandd,
            groupTravel: ibJS.groupTravel,
            missionaryTravel: ibJS.missionaryTravel,
            kidnapRansom: ibJS.kidnapRansom,
            expatriates: ibJS.expatriates,
            marine: ibJS.marine,
            missionaryLongTerm: ibJS.missionaryLongTerm,
            shortTermMedical: ibJS.shortTermMedical,
            shortTermDomesticMedical: ibJS.shortTermDomesticMedical,
            longTermMedical: ibJS.longTermMedical,
            life: ibJS.life,
            disability: ibJS.disability,
            groupMedical: ibJS.groupMedical,
            dental: ibJS.dental,
            visitorUSA: ibJS.visitorUSA,
            currentQuoteSelect: '',
            hasTripCal: false,
            heroUrl: ibJS.newQuoteUrl,
            isFormSubmitted: false,
            sessionTime: null,
            disableSelection: false
        }
    },
    created: function(){
        let tripCalSelected = getItemSessionStorage("hasTripCal");
        if(tripCalSelected == 'yes') {
            this.hasTripCal = true;
        }
        if(this.tripInsurance && !this.visitorOutsideUSA && !this.covid19 && !this.visitorUSA) {
            this.currentQuoteSelect = '';
        }
    },
    mounted: function() {
        var $elms = document.querySelectorAll("[add-index]");
        Array.prototype.forEach.call($elms, (el,i) => {
            if(i % 2 !== 0) {
                $(el).addClass("c-Home__section--white c-Home__section--chevron-white");
            }
        });

        var $elms2 = document.querySelectorAll("[add-index-covid]");
        Array.prototype.forEach.call($elms2, (el,i) => {
            if(i % 2 == 0) {
                $(el).addClass("c-Home__section--white c-Home__section--chevron-white");
            }
        });

        if(window.ibJS.homeLanding) {
            this.init();
        }

        if (ibJS.affiliatevalues?.picturePreference != 'STD') document.querySelector(".c-Home-primary__hero-alternate")?.classList.remove('c-Home-primary__hero-alternate');
    },
    methods: {
        init() {
            /*homeHeroSection*/
            let homeHeroSection = window.homeHeroSection;
            for(let i in homeHeroSection) {
                if(homeHeroSection[i] && i != "counter"){
                    homeHeroSection.counter+=1;
                }
            }
            
            let $homeHeroSection = document.querySelectorAll(".homeHeroSection");
            let $homeHeroSectionContainer = document.querySelectorAll(".c-Home-primary__hero-quote-neutral__item");

            if(homeHeroSection && $homeHeroSection.length && $homeHeroSectionContainer.length) {
                const className = `c-Home-primary__hero-quote-neutral__item${numberString[homeHeroSection.counter -1]}`;
                if (className !== 'c-Home-primary__hero-quote-neutral__item--six') {
                    $homeHeroSectionContainer[0].classList.remove('c-Home-primary__hero-quote-neutral__item--six');
                    $homeHeroSectionContainer[0].classList.add(className);
                }
            }
        },
        setSession: function() {
            // setItemSessionStorage('quotePlan', this.currentQuoteSelect);            
            if(this.currentQuoteSelect != 'covid19') {
                this.hasTripCal = false;
            }

            if(!this.hasTripCal) {
                setItemSessionStorage('hasTripCal', 'no');
            } else {
                setItemSessionStorage('hasTripCal', 'yes');
            }
        },
        showGeoModal: function (e, url) {
            var url = url || null;
            if (url != null && url.indexOf('$PLANID') > -1 && e.target.dataset) {
                url = url.replace('$PLANID', e.target.dataset.planId);
                url = url.replace('$PLANTYPE', e.target.dataset.planType);
                url = url.replace('$CURRENTLANG', e.target.dataset.currentLang);
            }
            clearTimeout(this.sessionTime);
            this.sessionTime = null;
            this.showModal(url);
        },
        setHeroUrl (isClick, quoteSelected) {
            if (!this.disableSelection) {
                this.currentQuoteSelect = (this.currentQuoteSelect == 'covid19' || this.hasTripCal) && !quoteSelected ? 'covid19' : !quoteSelected && this.currentQuoteSelect ? this.currentQuoteSelect : quoteSelected;

                if (isClick==false && !ibJS.tripInsurance) isClick = true;
                if (this.currentQuoteSelect) {
                    switch (this.currentQuoteSelect) {
                        case 'visitorUSA':
                            this.heroUrl = window.ibJS.heroUrl?.travelToUsa;
                            break;
                        case 'covid19':
                            this.heroUrl = this.hasTripCal ? window.ibJS.heroUrl?.tripIns : window.ibJS.heroUrl?.travelOutsideUsa;                        
                            break;
                        case 'domesticUSA':
                            this.heroUrl = window.ibJS.heroUrl?.domestic;
                            break;
                    }
                    if (isClick) {
                        this.setSession();
                        this.disableSelection = true;
                        setTimeout(() => {
                            this.disableSelection = false;
                        }, 1000);
                        window.location.href = this.heroUrl;
                    }
                    else if (isClick === false) {
                        this.sessionTime = setTimeout(() => {
                            if (this.sessionTime) {
                                this.disableSelection = true;
                                setTimeout(() => {
                                    this.disableSelection = false;
                                }, 1000);
                                this.setSession();
                                if(this.hasTripCal && window.ibJS.heroUrl?.tripIns) this.heroUrl = window.ibJS.heroUrl.tripIns;
                                window.location.href = this.heroUrl;
                            }
                        }, 7000);
                    }
                } else {
                    if (isClick) this.isFormSubmitted = true;
                }
            }
        },
        hasAffiliateVal(quoteSelected) {
            switch (quoteSelected) {
                case 'visitorUSA':
                    return this.visitorUSA;
                case 'covid19':
                    return this.visitorOutsideUSA;    
                case 'domesticUSA':
                    return this.tripInsurance;
            }           
        }
    }
});
